import React, { useState } from 'react';

const TruckForm = ({ truck, onCancelEdit, onSuccessfulSubmit }) => {
    const [formData, setFormData] = useState({
      unitNumber: truck.unitNumber,
      manufacturer: truck.manufacturer,
      style: truck.style,
      assignment: truck.assignment,
    });
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const apiEndpoint = "https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/updatetruck"; // Update this with your API Gateway URL
  
      // Include 'id' and 'type' in the request body
      const requestBody = {
        id: truck.id,
        type: truck.type,
        ...formData,
      };
  
      try {
        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const responseJson = await response.json();
  
        // Reset form fields and close the form after successful submission
        setFormData({
          unitNumber: '',
          manufacturer: '',
          style: '',
          assignment: '',
        });
        onSuccessfulSubmit(); // Call this after successful submission
        onCancelEdit();
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle error feedback to user here
      }
    };

  return (
    <div>
      <h2>Edit Truck</h2>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="input-row">
          <div className="input-group">
            <label htmlFor="unitNumber">Unit Number:</label>
            <input
              type="text"
              id="unitNumber"
              name="unitNumber"
              value={formData.unitNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="manufacturer">Manufacturer:</label>
            <input
              type="text"
              id="manufacturer"
              name="manufacturer"
              value={formData.manufacturer}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="style">Style:</label>
            <input
              type="text"
              id="style"
              name="style"
              value={formData.style}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="assignment">Assignment:</label>
            <input
              type="text"
              id="assignment"
              name="assignment"
              value={formData.assignment}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="button-container">
          <button type="submit">Save</button>
          <button type="button" onClick={onCancelEdit}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default TruckForm;