/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_s3": {
        "bucket": "it-webapp-imagestorage",
        "region": "us-east-1",
        "publicAccess": true
    }
};


export default awsmobile;
