import React from 'react';
import {Amplify} from 'aws-amplify';
import awsExports from './aws-exports';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import './App.css';
import Home from './Home.jsx';
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import CleanTruck from "./CleanTruck.jsx";
import UpdatedStats from "./UpdatedStats.jsx";
import AdminDash from "./AdminDash.jsx"; // Import the protected Admin component

Amplify.configure(awsExports);

const App = () => {

  return (
    <Router>
      <div>
        <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="detailing" element={<CleanTruck />} />
          <Route path="performance" element={<UpdatedStats />} />
          <Route path="admin" element={<AdminDash />} />  {/* Protected Route */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;  // Removed withAuthenticator here