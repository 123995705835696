import React from 'react';
import TruckList from './TruckList.jsx';
import DetailingList from './DetailingList.jsx';
import CleaningEvents from './CleaningEvents.jsx';
import WorkOrders from './WorkOrders.jsx';
import FileUploadForm from './FileUploadForm.jsx';
const MainContent = ({ selectedOption }) => {
  switch (selectedOption) {
    case 'listTrucks':
      return <TruckList />;
    case 'detailing':
      return <DetailingList />;
    case 'cleaningEvent':
      return <CleaningEvents />;
    case 'fileUpload':
      return <FileUploadForm />;
    case 'workOrder':
      return <WorkOrders />;
    // Add cases for other options here
    default:
      return null;
  }
};

export default MainContent;
