import React, { useState, useEffect } from 'react';
import DetailingForm from './DetailingForm.jsx'; // Import your form component

export default function DetailingList() {
  const [items, setItems] = useState([]);
  const [selectedCleaningItem, setSelectedCleaningItem] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'unitNumber', direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');

  const apiUrl = 'https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/alltrucks?type=900';

  useEffect(() => {
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => setItems(data || []))
      .catch(error => console.error('Fetching error:', error));
  }, []);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems.filter(item =>
      item.unitNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.style.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.lastCleaned.toLowerCase().includes(searchTerm.toLowerCase())||
      item.availStart.toLowerCase().includes(searchTerm.toLowerCase())||
      item.nextStart.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [items, sortConfig, searchTerm]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = (item) => {
    setSelectedCleaningItem(item);
  };

  const handleCancelEdit = () => {
    setSelectedCleaningItem(null);
  };

  // Inside TruckList component
const fetchItems = () => {
  fetch(apiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  })
    .then(response => response.json())
    .then(data => {
      setItems(data || []);
      // Ensure items are sorted initially as required
      requestSort('unitNumber');
    })
    .catch(error => console.error('Fetching error:', error));
};

useEffect(() => {
  fetchItems(); // Fetch items on component mount
}, []);

const handleAddCleaningItem = () => {
  const newItem = {
    id: new Date().toISOString(), // Current timestamp as ID
    type: 900, // Predefined type
    unitNumber: '', // Blank fields for the new truck form
    manufacturer: '',
    style: '',
    assignment: '',
  };
  setSelectedCleaningItem(newItem);
};

return (
  <div className="container listDiv truckContent">
    <div className="search-and-add-bar">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button onClick={handleAddCleaningItem}>Add Cleaning</button>
    </div>
      <div className="table-container">
  <table>
    <thead>
      <tr>
        <th className="styleCol1" onClick={() => requestSort('unitNumber')}>Unit Number</th>
        <th className="styleCol2" onClick={() => requestSort('style')}>Style</th>
        <th className="styleCol1" onClick={() => requestSort('lastCleaned')}>Last Cleaned</th>
        <th className="styleCol1" onClick={() => requestSort('availStart')}>Avalability</th>
        <th className="styleCol1" onClick={() => requestSort('nextStart')}>Next Cleaning</th>
      </tr>
    </thead>
    {/* Apply the scrollable-tbody class here */}
    <tbody className="scrollable-tbody">
    {sortedItems.map((item) => (
      <tr key={item.id} onClick={() => handleRowClick(item)}>
        <td className="styleCol1">{item.unitNumber}</td>
        <td className="styleCol1">{item.style}</td>
        <td className="styleCol1">{item.lastCleaned}</td>
        <td className="styleCol2">{item.availStart} to {item.availEnd}</td>
        <td className="styleCol1">{item.nextStart}</td>
      </tr>
    ))}
    </tbody>
  </table>
</div>
      {selectedCleaningItem && (
      <div className="container formDiv">
        <DetailingForm item={selectedCleaningItem} onCancelEdit={handleCancelEdit} onSuccessfulSubmit={fetchItems} />
      </div>
    )}
  </div>
);
}
