import React, { useState, useEffect } from 'react';

const CleaningForm = ({ data, onFormSubmit }) => {
  const [formData, setFormData] = useState({
    unitNumber: '',
    dateCleaned: '',
    cleanedBy: '',
    truckCond: '',
    cleaningNotes: '',
    cleaningType: 'Standard',
    files: [],
  });
  const [inputKey, setInputKey] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [flashMessage, setFlashMessage] = useState('');
  const [duplicateEntry, setDuplicateEntry] = useState(null);
  let timeoutId = null;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, files: Array.from(e.target.files) });
  };

  const getPresignedURL = async (file, newName) => {
    try {
      console.log(`Requesting presigned URL for ${newName} with file type: ${file.type}`);
      const response = await fetch('https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/image-upload', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          objectKey: newName,
          contentType: file.type,
          fileType: file.type // Include fileType
        })
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      const { presignedUrl } = await response.json();
      console.log(`Presigned URL received for ${newName}`);
      return presignedUrl;
    } catch (error) {
      console.error('Error fetching presigned URL:', error);
      throw error;
    }
  };

  const uploadFileToS3 = async (file, url) => {
    try {
        console.log('Uploading file to S3:', file.name, 'at URL:', url);
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type,
            },
            body: file,
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to upload file: ${errorText}`);
        }
        console.log('File uploaded successfully:', file.name);
        return true;
    } catch (error) {
        console.error('Error uploading file to S3:', error);
        throw error;
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setDuplicateEntry(null); // Reset duplicate state

    try {
      const currentDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).replace(/\//g, '');

      const truckNumber = formData.unitNumber;

      console.log('Starting file upload process...');
      const uploadedImageNames = await Promise.all(formData.files.map(async (file, index) => {
        const newName = `nonopt.${currentDate}.${truckNumber}.${index + 1}`;
        console.log(`Processing file ${file.name} as ${newName}`);
        const presignedUrl = await getPresignedURL(file, newName);
        await uploadFileToS3(file, presignedUrl);
        return newName;
      }));

      const requestBody = {
        id: new Date().toISOString(),
        type: 1000,
        unitNumber: formData.unitNumber,
        dateCleaned: formData.dateCleaned,
        cleanedBy: formData.cleanedBy,
        truckCond: formData.truckCond,
        cleaningNotes: formData.cleaningNotes,
        cleaningType: formData.cleaningType,
        images: uploadedImageNames,
      };

      console.log('Submitting cleaning form data...');
      const response = await fetch('https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/submitCleaning', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      // Handle conflict status due to duplicate entry
      if (response.status === 409) {
        const { duplicateEntry, message } = await response.json();
        console.log('Duplicate entry detected:', message);
        setDuplicateEntry(duplicateEntry);
        setFlashMessage({ type: 'warning', message });
        return; // Do not reset the form if there’s a duplicate
      }

      if (!response.ok) throw new Error('Failed to submit form.');

      console.log('Form submitted successfully');
      if (!duplicateEntry && formData.cleaningType === 'Standard') {
        const updateParams = {
          unitNumber: formData.unitNumber,
          dateCleaned: formData.dateCleaned,
        };

        console.log('Updating cleaning list...');
        const updateResponse = await fetch('https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/updatecleaninglist', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updateParams),
        });

        if (!updateResponse.ok) throw new Error('Failed to update cleaning list.');

        console.log('Cleaning list updated successfully, refreshing page...');
        setTimeout(() => {
          window.location.reload(); // Refresh the page after a short delay
        }, 1000); // 1 second delay to show the success message before refresh
      }

      setFlashMessage({ type: 'success', message: 'Form submitted successfully!' });
      timeoutId = setTimeout(clearFlashMessage, 5000);
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
      setFlashMessage({ type: 'error', message: error.message || 'Error submitting form. Please try again.' });
      timeoutId = setTimeout(clearFlashMessage, 5000);
    } finally {
      setLoading(false);
      // Don't reset form here since it causes issues for duplicate entries
    }
  };

  // Handle the update action for an existing entry
  const handleUpdateEntry = async () => {
    try {
      console.log('Updating existing entry...');
      const requestBody = {
        ...formData,   // Keep the form data
        id: duplicateEntry.id,  // Use the existing entry's ID for the update
        images: duplicateEntry.images || formData.files.map(file => file.name), // Keep or update images
        isUpdate: true,  // Indicating that this is an update request
        type: 1000,
      };

      const response = await fetch('https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/submitCleaning', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) throw new Error('Failed to update existing entry.');

      console.log('Entry updated successfully');
      setFlashMessage({ type: 'success', message: 'Entry updated successfully!' });
      setDuplicateEntry(null);
      timeoutId = setTimeout(clearFlashMessage, 5000);
      resetForm();
    } catch (error) {
      console.error('Error updating entry:', error);
      setFlashMessage({ type: 'error', message: 'Failed to update entry.' });
    }
  };

  const resetForm = () => {
    setFormData({
      unitNumber: '',
      dateCleaned: '',
      cleanedBy: '',
      truckCond: '',
      cleaningNotes: '',
      cleaningType: 'Standard',
      files: [],
    });
    setInputKey(Date.now());
  };

  const clearFlashMessage = () => {
    setFlashMessage('');
  };

  return (
    <div className="container formDiv right">
      <form action="/submit" method="post" className="cleaning-form" onSubmit={handleSubmit}>
        {/* Form fields */}
        <div>
          <label htmlFor="unitNumber" className="formItem">Unit Number:</label>
          <input
            type="text"
            id="unitNumber"
            name="unitNumber"
            className="form-input"
            value={formData.unitNumber}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="dateCleaned" className="formItem">Date Cleaned:</label>
          <input
            type="date"
            id="dateCleaned"
            name="dateCleaned"
            className="form-input"
            value={formData.dateCleaned}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="cleanedBy" className="formItem">Cleaned By:</label>
          <select
            name="cleanedBy"
            id="cleanedBy"
            className="form-input"
            value={formData.cleanedBy}
            onChange={handleInputChange}
          >
            <option value="" disabled hidden>Select a staff</option>
            {data && data.map((staffMember) => (
              <option key={staffMember.name} value={staffMember.name}>
                {staffMember.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="cleaningType" className="formItem">Cleaning Type:</label>
          <select
            id="cleaningType"
            name="cleaningType"
            className="form-input"
            value={formData.cleaningType}
            onChange={handleInputChange}
          >
            <option value="Standard">Standard</option>
            <option value="Emergency">Emergency</option>
            <option value="Intake">Intake</option>
          </select>
        </div>
        <div>
          <label htmlFor="truckCond" className="formItem">Truck Condition:</label>
          <select
            id="truckCond"
            name="truckCond"
            className="form-input"
            value={formData.truckCond}
            onChange={handleInputChange}
          >
            <option value="">Select condition</option>
            <option value="excellent">Excellent</option>
            <option value="good">Good</option>
            <option value="fair">Fair</option>
            <option value="poor">Poor</option>
          </select>
        </div>
        <div>
          <label htmlFor="cleaningNotes" className="formItem">Cleaning Notes:</label>
          <textarea
            id="cleaningNotes"
            name="cleaningNotes"
            className="form-input"
            value={formData.cleaningNotes}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="fileUpload" className="formItem">File Upload:</label>
          <input
            key={inputKey}
            type="file"
            id="fileUpload"
            name="fileUpload"
            className="form-input"
            accept=".jpg,.jpeg,.png"
            onChange={handleImageChange}
            multiple
          />
        </div>
        <button type="submit" className="form-button" disabled={loading}>Submit</button>
      </form>

      {/* Duplicate entry prompt */}
      {duplicateEntry && (
        <div className="duplicate-entry-warning">
          <p>A similar entry already exists. Do you want to update it?</p>
          <button onClick={handleUpdateEntry}>Update Entry</button>
          <button onClick={() => setDuplicateEntry(null)}>Cancel</button>
        </div>
      )}

      {flashMessage && (
        <div className={`flash-message ${flashMessage.type}`}>
          {flashMessage.message}
        </div>
      )}
    </div>
  );
};

export default CleaningForm;
