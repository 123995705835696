import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

export default function FileUploadComponent() {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState('');

    const onDrop = useCallback(acceptedFiles => {
        setFiles(currentFiles => [...currentFiles, ...acceptedFiles]);
    }, []);

    const removeFile = (file) => {
        setFiles(currentFiles => currentFiles.filter(item => item !== file));
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const uploadFile = async (file) => {
        const fileType = file.type.startsWith('image/') ? 'image' : 'document';
        const apiUrl = 'https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/image-upload';
    
        const jsonPayload = JSON.stringify({
            fileType: fileType,
            objectKey: file.name,
            contentType: file.type
        });
    
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: jsonPayload,
        };
    
        try {
            const presignResponse = await fetch(apiUrl, options);
            const text = await presignResponse.text(); // Getting the text response
    
            // Check if the text is empty or not
            if (!text) {
                throw new Error('Received empty response');
            }
    
            const response = JSON.parse(text); // Parsing the text to JSON
    
            if (!presignResponse.ok) {
                throw new Error(`Failed to fetch presigned URL: ${presignResponse.statusText}`);
            }
    
            const { presignedUrl } = response;
    
            const uploadOptions = {
                method: 'PUT',
                body: file,
                headers: { 'Content-Type': file.type },
            };
    
            const uploadResponse = await fetch(presignedUrl, uploadOptions);
            if (!uploadResponse.ok) {
                throw new Error(`HTTP error! status: ${uploadResponse.status}`);
            }
    
            return uploadResponse.json(); // or handle it accordingly
        } catch (error) {
            console.error('Error during file upload:', error);
            throw error; // Re-throw the error after logging
        }
    };
    
    

    const uploadFiles = async () => {
        setUploading(true);
        setMessage('');
        setProgress(0);
    
        try {
            const uploadPromises = files.map(uploadFile); // Create a promise for each file upload
            const responses = await Promise.all(uploadPromises); // Wait for all promises to resolve
    
            responses.forEach(response => {
                console.log('Upload response:', response);
            });
    
            setMessage('All files uploaded successfully!');
        } catch (error) {
            setMessage(`Error during file upload: ${error.message}`);
            console.error('Uploading error:', error);
        }
    
        setUploading(false);
        setFiles([]);
        setProgress(0);
    };

    return (
        <div className="container listDiv">
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <ul>
                {files.map((file, index) => (
                    <li key={index}>
                        {file.name}
                        <button type="button" onClick={() => removeFile(file)}>Remove</button>
                    </li>
                ))}
            </ul>
            <button onClick={uploadFiles} disabled={uploading || files.length === 0}>Upload Files</button>
            {uploading && <div className="progress-bar" style={{ width: `${progress}%` }}>{progress}%</div>}
            {message && <p className="flash-message">{message}</p>}
        </div>
    );
}
