import React, { useState, useEffect } from 'react';

export default function UpdatedStats() {
  const [performanceLists, setPerformanceLists] = useState({
    items: [],
    fleetAverage: { onTime: 0, safetyScore: 0 },
  });

  // Function to get the start (Sunday) and end (Saturday) dates of the current week
  const getCurrentWeekDates = () => {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
    const lastDay = new Date(currentDate.setDate(firstDay.getDate() + 6));

    const formatDate = (date) => {
      const d = new Date(date);
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      const year = d.getFullYear();
      return `${month}/${day}/${year}`;
    };

    return {
      firstDay: formatDate(firstDay),
      lastDay: formatDate(lastDay),
    };
  };

  const { firstDay, lastDay } = getCurrentWeekDates();

  useEffect(() => {
    const fetchPerformanceData = async () => {
      try {
        const response = await fetch('https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/alltrucks?type=150');
        const data = await response.json();
    
        const items = data
          .filter(item => item.empStatus !== 'I') // Filter out items with status "I"
          .map(item => ({
            driver: `${item.empName[0].S} ${item.empName[1].S}`, // Access the 'S' property for each name part
            onTime: parseFloat(item.performance[0]), // Ensure onTime is a number
            safetyScore: parseFloat(item.performance[1]), // Ensure safetyScore is a number
            status: item.empStatus, // Map the status
          }));
    
        // Calculate fleet averages, ignoring non-number entries
        const validOnTime = items.filter(item => !isNaN(item.onTime));
        const validSafetyScore = items.filter(item => !isNaN(item.safetyScore));
    
        const totalOnTime = validOnTime.reduce((sum, item) => sum + item.onTime, 0);
        const totalSafetyScore = validSafetyScore.reduce((sum, item) => sum + item.safetyScore, 0);
    
        const fleetAverage = {
          onTime: (totalOnTime / validOnTime.length).toFixed(2),
          safetyScore: (totalSafetyScore / validSafetyScore.length).toFixed(2),
        };
    
        // Sort the items by driver name in ascending order
        const sortedItems = items.sort((a, b) => a.driver.localeCompare(b.driver));
    
        setPerformanceLists({ items: sortedItems, fleetAverage });
      } catch (error) {
        console.error('Error fetching performance data:', error);
      }
    };
    

    fetchPerformanceData();
  }, []);

  return (
    <div>
      {/* Title section */}
      <section id="Title" className="formTitle colored-bg">
        <div className="formHead">
          <h3>Driver Performance Scores</h3>
          <h2>Week Of: {firstDay} to {lastDay}</h2>
        </div>
      </section>
      <section id="main" className="colored-bg">
        <div className="container listDiv">
          <h3>Performance Data</h3>
          <table>
            <thead>
              <tr>
                <td style={{ fontWeight: 'bold', color: '#567fa9' }}>Fleet Average</td>
                <td><span style={{ fontWeight: 'bold', color: '#567fa9' }}>On Time%:</span> {performanceLists.fleetAverage.onTime} %</td>
                <td><span style={{ fontWeight: 'bold', color: '#567fa9' }}>Safety Score:</span> {performanceLists.fleetAverage.safetyScore}</td>
              </tr>
            </thead>
          </table>
            <table className="scrollable-table">
              <thead>
                <tr>
                  <th>Driver</th>
                  <th style={{width:'100%'}}>On Time %</th>
                  <th>Safety Score</th>
                </tr>
              </thead>
              <tbody>
                {performanceLists.items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.driver}</td>
                    <td style={{ color: item.onTime < 95 ? '#FF4857' : 'white' }}>{item.onTime} %</td>
                    <td style={{ color: item.safetyScore < 850 ? '#FF4857' : 'white' }}>{item.safetyScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        <div className="container listDiv">
          <h3>~ NOTES ~</h3>
          <ul style={{ listStyle: 'none', fontWeight: 'bolder', margin: '5px 0' }}>
            <li>On Time Percentage
              <ul style={{ textAlign: 'left', fontWeight: 400 }}>
                <li>If your percentage is below 95% you may not be eligible for overtime or bonuses.</li>
              </ul>
            </li>
            <li>Safety Scores
              <ul style={{ textAlign: 'left', fontWeight: 400 }}>
                <li>If your score is below 850 you will lose your bonus hours and may not be eligible for overtime.</li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}
