import React from 'react';

export default function PendingTrucks({ truckList }) {
  // Calculate the total price
  const totalPrice = truckList.reduce((total, truck) => total + parseFloat(truck.price), 0);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th className="styleCol1">Unit Number</th>
            <th className="styleCol1">Style</th>
            <th className="styleCol2">Last Cleaned</th>
            <th className="styleCol1">Price</th>
          </tr>
        </thead>
        <tbody>
          {truckList.map((truck) => (
            <tr key={truck.unitNumber}>
              <td className="styleCol1">{truck.unitNumber}</td>
              <td className="styleCol1">{truck.style}</td>
              <td className="styleCol2">{truck.dateCleaned}</td>
              <td className="styleCol1">
                {`$${parseFloat(truck.price).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2">Count:          {truckList.length}</td>
            <td colSpan="1">Total:</td>
            <td className="styleCol1">
              {`$${totalPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
