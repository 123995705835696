import React, { useState } from 'react';
import MainContent from './MainContent';


const AdminDashboard = () => {
  const [selectedOption, setSelectedOption] = useState('listTrucks');

  const handleMenuClick = (option) => {
    setSelectedOption(option.target.value);
  };

  var headingText = "";

  switch(selectedOption){
    case "listTrucks":
      headingText= "Truck List";
      break;
    case "detailing":
      headingText= "Detailing Schedule Items";
      break;
    case "cleaningEvent":
      headingText= "Cleaning Reports";
      break;
    case "workOrder":
      headingText= "Work Orders";
      break;
    case "fileUpload":
      headingText= "Upload Files";
      break;
  }

  return (
    <div className="app-container dashboard">
      <section id="Title" className="formTitle colored-bg">
        <div className="formHead">
          <h3>Administration Console</h3>
        </div>
      </section>
      <div className="main-container">
        <div className="listDiv dataDiv colored-bg">
          <div>
              <h2 className="content-title">{headingText}</h2>
          </div>
          <div className="search-and-add-bar">
              <select value={selectedOption} onChange={handleMenuClick} className="timeframe-dropdown">
                <option value="cleaningEvent">Cleaning Reports</option>
                <option value="detailing">Detailing</option>
                <option value="listTrucks">Truck List</option>
                <option value="fileUpload">Upload Files</option>
                <option value="workOrder">Work Orders</option>
              </select>
          </div>
           <MainContent selectedOption={selectedOption} />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

