import React, { useState, useEffect } from 'react';

export default function DetailsView({ item, onClose }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedCount, setLoadedCount] = useState(0);

  useEffect(() => {
    setIsLoading(true);  // Start loading
    const fetchImages = async () => {
      setIsLoading(true);
      setLoadedCount(0); // Reset loaded count when starting to load new images
      try {
        const urls = await Promise.all(item.images.map(async (imageKey) => {
          if (imageKey.startsWith('nonopt.')) {
            imageKey = imageKey.replace('nonopt.', '');
          }
          const response = await fetch(`https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/getImage`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({ imageKey })
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          const data = await response.json();
          setLoadedCount((prev) => prev + 1);  // Increment loaded count
          return `data:image/jpeg;base64,${data.image}`;
        }));
    
        setImageUrls(urls);
      } catch (error) {
        console.error("Failed to fetch images", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (item.images.length > 0) {
      fetchImages();
    }
  }, [item.images]);

  const next = () => setCurrentIndex(prevIndex => Math.min(prevIndex + 1, imageUrls.length - 1));
  const prev = () => setCurrentIndex(prevIndex => Math.max(0, prevIndex - 1));

  const handleSaveImage = () => {
    const link = document.createElement('a');
    link.href = imageUrls[currentIndex];
    link.download = `Image_${currentIndex + 1}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImageLoad = (event) => {
    const naturalHeight = event.target.naturalHeight;
    const naturalWidth = event.target.naturalWidth;
    const aspectRatio = naturalWidth / naturalHeight;
    const fixedHeight = 300; // the fixed height you want
    const calculatedWidth = fixedHeight * aspectRatio;
    event.target.style.width = `${calculatedWidth}px`;
    event.target.style.height = `${fixedHeight}px`;
  };

  const progressBarStyle = {
    width: `${(loadedCount / item.images.length) * 100}%`,
    height: '20px',
    backgroundColor: 'green',
    transition: 'width 0.5s ease-in-out'
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>X</button>
        <div className="container modal-group">
          <div className="detail-data">
            <div className="details-row"><strong>Unit Number:</strong> {item.unitNumber}</div>
            <div className="details-row"><strong>Cleaning Type:</strong> {item.cleaningType}</div>
            <div className="details-row"><strong>Cleaned By:</strong> {item.cleanedBy}</div>
          </div>
          <div className="detail-data">
            <div className="details-row"><strong>Date Cleaned:</strong> {item.dateCleaned}</div>
            <div className="details-row"><strong>Price:</strong> ${item.price}</div>
            <div className="details-row"><strong>Paid:</strong> {item.paid}</div>
          </div>
        </div>
        <textarea className="scrollable-textbox" value={item.cleaningNotes || ''} readOnly />
      <div className="image-slideshow-container">
        {isLoading ? (
          <div className="progress-container">
            <div style={progressBarStyle}></div>
            Loading {loadedCount}/{item.images.length}
          </div>
        ) : (
          <>
            <button className="carousel-control left" onClick={prev} disabled={currentIndex === 0}>{"<"}</button>
            <img
              src={imageUrls[currentIndex]}
              alt={`Detail ${currentIndex + 1}`}
              className="displayed-image"
              onLoad={handleImageLoad}
            />
            <div className="image-index">{currentIndex + 1}/{imageUrls.length}</div>
            <button className="carousel-control right" onClick={next} disabled={currentIndex >= imageUrls.length - 1}>{">"}</button>
          </>
        )}
        <button className="save-button" onClick={handleSaveImage}>Save</button>
      </div>
    </div>
  </div>
)};