import React, { useState, useEffect } from 'react';
import CleanList from './CleanList';
import CleanForm from './CleanForm';
import PendingTrucks from './PendingTrucks';
import './styles.css';

export default function CleanTruck() {

  const [cleaningLists, setCleaningLists] = useState({
    currentWeek: { startDate: null, endDate: null, list: [] },
    nextWeek: { startDate: null, endDate: null, list: [] },
    cleaningStaff: [], 
    pendingTrucks:[], 
  });

  const [forceRerender, setForceRerender] = useState(false);
  
  useEffect(() => {
    const fetchCleaningLists = async () => {
      try {
        const response = await fetch('https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/detailing');
        const responseData = await response.json();
    
        if (responseData.success) {
          
          // Access the cleaningLists object from the responseData
          const { cleaningLists, cleaningStaff, pendingTrucks } = responseData;
          
          // Update the state with the cleaningLists object
          setCleaningLists({
            currentWeek: cleaningLists.currentWeek,
            nextWeek: cleaningLists.nextWeek,
            cleaningStaff: cleaningStaff,
            pendingTrucks: pendingTrucks
          });
        } else {
          console.error('Failed to fetch cleaning lists:', responseData.message);
        }
      } catch (error) {
        console.error('Error fetching cleaning lists:', error);
      }
    };
    fetchCleaningLists();
  }, [forceRerender]); // Include dependencies if needed

  const handleFormSubmission = () => {
    setForceRerender(prev => !prev); // Toggle the state to trigger useEffect
  };

  return (
    <div>
      <section id="Title" className="formTitle colored-bg">
        <div className="formHead">
          <h3>Truck Detailing Information</h3>
        </div>
      </section>
    <section id="main" className="colored-bg">
      <div className="container left">
      <div className="container listDiv">
  <h4 className="listHead">Current week: {cleaningLists.currentWeek?.startDate} to {cleaningLists.currentWeek?.endDate}</h4>
  {cleaningLists.currentWeek && <CleanList truckList={cleaningLists.currentWeek.list} />}
</div>
<div className="container listDiv">
  <h4 className="listHead">Upcoming week: {cleaningLists.nextWeek.startDate} to {cleaningLists.nextWeek.endDate}</h4>
  {cleaningLists.nextWeek && <CleanList truckList={cleaningLists.nextWeek.list} />}
</div>
      </div>
      <div className="container formDiv center listDiv">
          {cleaningLists.cleaningStaff && (
            <CleanForm data={cleaningLists.cleaningStaff} onFormSubmit={handleFormSubmission} />
          )}
      </div>
      <div className="container">
        <div className="container listDiv">
          <h4 className = "listHead">Completed cleanings awaiting payout: </h4>
          {/* Add a check for the existence of cleaningLists.cleaning before rendering */}
          {cleaningLists.pendingTrucks && (
            <PendingTrucks className="listDiv" truckList={cleaningLists.pendingTrucks} />
          )}
        </div>
      </div>
    </section>
  </div>
  );
}
