import React, { useState, useEffect } from 'react';
import moment from 'moment'; // Import moment to handle date formatting

export default function WoDetails({ item, onClose, editMode, setEditMode }) {
    const [details, setDetails] = useState(item || {});
    const statuses = ['Open', 'Completed'];
    const unitHealthOptions = ['Available', 'Unavailable'];

    useEffect(() => {
        setDetails({
            ...item,
            dateEntered: item && item.dateEntered ? moment(item.dateEntered, 'MM/DD/YYYY').format('YYYY-MM-DD') : '',
            dateCompleted: item && item.dateCompleted ? moment(item.dateCompleted, 'MM/DD/YYYY').format('YYYY-MM-DD') : ''
        });
    }, [item]);

    const handleSave = () => {
        const url = 'https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/updateWO';
        const saveDetails = {
            ...details,
            dateEntered: details.dateEntered ? moment(details.dateEntered, 'YYYY-MM-DD').format('MM/DD/YYYY') : '',
            dateCompleted: details.dateCompleted ? moment(details.dateCompleted, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''
        };
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(saveDetails),
        })
        .then(response => response.json())
        .then(data => {
            onClose();
        })
        .catch(error => {
            console.error('Error during save operation:', error);
        });
    };

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>X</button>
        <div className="container modal-group">
          <div className="detail-data">
            {editMode ? (
            <div className="details-row">
                <label>Unit Number:</label>
                <input
                    type="text"
                    className="input-style"
                    value={details.unitNumber}
                    onChange={e => setDetails({...details, unitNumber: e.target.value})}
                />
            </div>
            ) : (
                <div className="details-row"><strong>Unit Number:</strong> {details.unitNumber}</div>
            )}
            {editMode ? (
                <div className="details-row">
                <label>Block ID:  </label>
                <input
                type="text"
                className="input-style"
                value={details.blockID}
                onChange={e => setDetails({...details, blockID: e.target.value})}
                />
                </div>
            ) : (
                <div className="details-row"><strong>Block ID:</strong> {details.blockID}</div>
            )}
            {editMode ? (
                <div className="details-row">
                <label>Entered By:  </label>
                <input
                type="text"
                className="input-style"
                value={details.enteredBy}
                onChange={e => setDetails({...details, enteredBy: e.target.value})}
                />
                </div>
            ) : (
                <div className="details-row"><strong>Entered By:</strong> {details.enteredBy}</div>
            )}
            </div>
            <div className="detail-data">
            {editMode ? (
                <div className="details-row">
                <label>Work Order:  </label>
                <input
                type="text"
                className="input-style"
                value={details.woNum}
                onChange={e => setDetails({...details, woNum: e.target.value})}
                />
                </div>
            ) : (
                <div className="details-row"><strong>Work Order:</strong> {details.woNum}</div>
            )}
            {editMode ? (
                <div className="details-row">
                <label>Status:</label>
                <select
                    className=" select-style"
                    value={details.status}
                    onChange={e => setDetails({...details, status: e.target.value})}
                >
                {statuses.map(status => (
                    <option key={status} value={status}>{status}</option>
                ))}
                </select>
                </div>
            ) : (
                <div className="details-row"><strong>Status:</strong> {details.status}</div>
            )}
            {editMode ? (
                <div className="details-row">
                    <label>Date Entered:</label>
                    <input
                        type="date"
                        className=" select-style"
                        value={details.dateEntered}
                        onChange={e => setDetails({...details, dateEntered: e.target.value})}
                    />
                </div>
            ) : (
                <div className="details-row"><strong>Date Entered:</strong> {details.dateEntered}</div>
            )}
            </div>
            <div className="detail-data">
            {editMode ? (
                <div className="details-row">
                    <label>Unit Health:</label>
                    <select
                        className=" select-style"
                        value={details.unitHealth}
                        onChange={e => setDetails({...details, unitHealth: e.target.value})}
                >
                    {unitHealthOptions.map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                    </select>
                </div>
            ) : (
                <div className="details-row"><strong>Unit Health:</strong> {details.unitHealth}</div>
            )}
            {editMode ? (
                <div className="details-row">
                    <label>Vendor:</label>
                    <input
                        list="vendors"
                        className=" select-style"
                        value={details.Vendor}
                        onChange={e => setDetails({...details, Vendor: e.target.value})}
                    />
                    <datalist id="vendors">
                        {/* Dynamically populate this list */}
                        <option value="AMERIT"></option>
                        <option value="CEI"></option>
                        <option value="Dealer"></option>
                        <option value="Goodyear"></option>
                        <option value="Velocity"></option>
                    </datalist>
                </div>
            ) : (
                <div className="details-row"><strong>Vendor:</strong> {details.Vendor}</div>
            )}
            {editMode ? (
                <div className="details-row">
                    <label>Date Completed:</label>
                    <input
                        type="date"
                        className=" select-style"
                        value={details.dateCompleted}
                        onChange={e => setDetails({...details, dateCompleted: e.target.value})}
                    />
                </div>
            ) : (
                <div className="details-row"><strong>Date Completed:</strong> {details.dateCompleted}</div>
            )}

            </div>
        </div>
        <div className="detail=data">
        {editMode ? (
            <div className="details-row">
                <label>Description:</label>
                <textarea
                    className="input-style scrollable-textbox"
                    value={details.description}
                    onChange={e => setDetails({...details, description: e.target.value})}
                />
            </div>
            ) : (
                <div className="details-row"><strong>Description:</strong> {details.description}</div>
            )} 
            </div>
            <div className="wo-images">
                <strong>Upload Files</strong>
            </div>
      <div className="controls">
                    {editMode ? (
                        <div>
                            <button onClick={handleSave}>Save</button>
                            <button onClick={onClose}>Cancel</button>
                        </div>
                    ) : (
                        <button onClick={() => setEditMode(true)}>Edit</button>
                    )}
                </div>
            </div>
        </div>
    );
}