import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';  // Import useNavigate hook
import ITLogo from "./images/IT-Logo.png";

export default function Header() {
  const navigate = useNavigate();
  const [selectedRoute, setSelectedRoute] = useState('/');  // Default route

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedRoute(value);
    navigate(value);
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navdiv">
          <img className="nav-logo navbar-brand" src={ITLogo} alt="Logo" />
        </div>
        <div className="navdivcenter">
          <h1 className="main-title">Innovative Trucking LLC</h1>
        </div>
        <div className="navdiv">
          <select onChange={handleSelectChange} value={selectedRoute} className="nav-link-dropdown select-style">
            <option value="/">Home</option>
            <option value="performance">Performance</option>
            <option value="detailing">Detailing</option>
            <option value="admin">Management</option>
          </select>
        </div>
      </nav>
    </div>
  );
}

