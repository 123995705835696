import React, { useState, useEffect } from 'react';
import DetailsView from './DetailsView.jsx';
import moment from 'moment'; // Import moment
// import DetailingForm from './DetailingForm.jsx'; // Import your form component

export default function CleaningEvents() {
    // State hooks
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedCleaningItem, setSelectedCleaningItem] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: 'unitNumber', direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');
  const [timeframe, setTimeframe] = useState('2 weeks');
  const [isDetailsVisible, setIsDetailsVisible] = useState(false); // Add this line
  const [selectedAction, setSelectedAction] = useState('');


  //Element constants
  const apiUrl = 'https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/alltrucks?type=1000';

  //Fetch items on component mount
  useEffect(() => {
    fetchItems(); // Fetch items on component mount
  }, []);

  //Apply filters whenever items or timeframe change
  useEffect(() => {
    applyFilters(items);
    requestSort(sortConfig.key);
    requestSort(sortConfig.key);
  }, [timeframe, items]);


  //Functions to fetch and filter items
  const fetchItems = () => {
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setItems(data || []);
        const initialCheckStates = {};
        data.forEach(item => {
            initialCheckStates[item.id] = false;  // Initialize all as unchecked
        });
        setCheckboxStates(initialCheckStates);
      })
      .catch(error => console.error('Fetching error:', error));
  };

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...filteredItems];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const valueA = a[sortConfig.key]?.toString().toLowerCase() || '';
        const valueB = b[sortConfig.key]?.toString().toLowerCase() || '';
        if (valueA < valueB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems.filter(item =>
      item.unitNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.truckCond?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.dateCleaned?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.cleanedBy?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.cleaningType?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.paid?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [items, sortConfig, searchTerm]);
  

  const applyFilters = (items) => {
    const now = moment(); // Use moment for current time
    const filtered = items.filter(item => {
        const itemDate = moment(item.dateCleaned, 'MM/DD/YYYY');
      switch (timeframe) {
        case '2 weeks':
          return now.diff(itemDate, 'weeks') <= 2;
        case '3 months':
          return now.diff(itemDate, 'months') <= 3;
        case '6 months':
          return now.diff(itemDate, 'months') <= 6;
        case 'all':
          return true;
        default:
          return true;
      }
    });
    setFilteredItems(filtered);
  };

  //Event handlers

  const handleCheckboxChange = (item) => {
    setCheckboxStates(prev => ({
        ...prev,
        [item.id]: !prev[item.id]  // Toggle the state
    }));
};

  const handleTimeframeChange = (e) => {
    setTimeframe(e.target.value);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleMarkAsPaid = () => {
    // Get the current date formatted as 'MM/DD/YYYY'
    const currentDate = moment().format('MM/DD/YYYY');
  
    // Filter out items that are checked and have 'Pending' status
    const itemsToMarkPaid = items.filter(item => checkboxStates[item.id] && item.paid === 'Pending');
  
    // Prepare the payload for the POST request
    const payload = itemsToMarkPaid.map(item => ({
      ...item,
      paid: currentDate // Set paid to the current date
    }));
  
    // Endpoint URL (adjust this to your API Gateway URL)
    const updateUrl = 'https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/updatecleaningreport';
  
    // Make a POST request to mark items as paid
    fetch(updateUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // CORS headers
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*', // Adjust this as needed
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      // Update the state only if the API call was successful
      setItems(currentItems =>
        currentItems.map(item => 
          payload.find(p => p.id === item.id) ? { ...item, paid: currentDate } : item
        )
      );
      // Reset checkboxes
      const newCheckboxStates = {...checkboxStates};
      itemsToMarkPaid.forEach(item => {
        newCheckboxStates[item.id] = false;
      });
      setCheckboxStates(newCheckboxStates);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleViewDetails = (item) => {
    setSelectedCleaningItem(item);  // Assuming this will hold the item details
    setIsDetailsVisible(true);
  };
  
// Function to close the modal
const handleCloseDetails = () => {
  setIsDetailsVisible(false);
  setSelectedCleaningItem(null);
  setSelectedAction(''); // Reset the action dropdown to default
  const resetCheckboxStates = {};
  Object.keys(checkboxStates).forEach(key => {
    resetCheckboxStates[key] = false; // Set all checkbox states to false
  });
  setCheckboxStates(resetCheckboxStates);
};


const handleActionChange = (event) => {
  const action = event.target.value;
  setSelectedAction(action); // Update the selected action state
  switch (action) {
    case 'markPaid':
      if (!isAllSelectedPending) {
        alert('Only items with pending payments can be marked as paid.');
        return;
      }
      handleMarkAsPaid();
      break;
    case 'viewDetails':
      const selectedItem = items.find(item => checkboxStates[item.id]);
      handleViewDetails(selectedItem);
      break;
    default:
      console.log('No action selected');
  }
};

  

const isAnySelected = Object.values(checkboxStates).some(state => state);
const isAllSelectedPending = items.every(item => 
    checkboxStates[item.id] ? item.paid === 'Pending' : true
);
const numberOfSelectedItems = Object.values(checkboxStates).filter(checked => checked).length;
const showDetails = () => setIsDetailsVisible(true);
const hideDetails = () => setIsDetailsVisible(false);


// const handleAddCleaningItem = () => {
//   const newItem = {
//     id: new Date().toISOString(), // Current timestamp as ID
//     type: 900, // Predefined type
//     unitNumber: '', // Blank fields for the new truck form
//     manufacturer: '',
//     style: '',
//     assignment: '',
//   };
//   setSelectedCleaningItem(newItem);
// };

//render component
return (
    <div className="container listDiv">
      <div className="search-and-add-bar">
        <div>
          <label className="input-label">Timeframe</label>
          <select value={timeframe} onChange={handleTimeframeChange} className="timeframe-dropdown">
            <option value="2 weeks">2 Weeks</option>
            <option value="3 months">3 Months</option>
            <option value="6 months">6 Months</option>
            <option value="all">All</option>
          </select>
        </div>
        <div>
          <label className="input-label">Search</label>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div>
          <label className="input-label">Actions</label>
          <select
            disabled={!isAnySelected}
            className="action-dropdown"
            value={selectedAction}
            onChange={handleActionChange}
          >
            <option value="">Select Action</option>
            <option value="markPaid" disabled={!isAllSelectedPending}>Mark Paid</option>
            <option value="viewDetails" disabled={numberOfSelectedItems !== 1}>View Details</option>
          </select>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="checkbox-header"></th>
              <th className="styleCol1" onClick={() => requestSort('unitNumber')}>Unit Number</th>
              <th className="styleCol1" onClick={() => requestSort('truckCond')}>Truck Condition</th>
              <th className="styleCol1" onClick={() => requestSort('dateCleaned')}>Last Cleaned</th>
              <th className="styleCol1" onClick={() => requestSort('cleaningType')}>Type</th>
              <th className="styleCol1" onClick={() => requestSort('cleanedBy')}>Detailer</th>
              <th className="styleCol1" onClick={() => requestSort('paid')}>Paid</th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            {sortedItems.map((item) => (
              <tr key={item.id}>
                <td className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={checkboxStates[item.id] || false}
                    onChange={() => handleCheckboxChange(item)}
                  />
                </td>
                <td className="styleCol1">{item.unitNumber}</td>
                <td className="styleCol1">{item.truckCond}</td>
                <td className="styleCol1">{item.dateCleaned}</td>
                <td className="styleCol1">{item.cleaningType}</td>
                <td className="styleCol1">{item.cleanedBy}</td>
                <td className="styleCol1">{item.paid}</td>
              </tr>
            ))}
          </tbody>
        </table>

</div>
{isDetailsVisible && selectedCleaningItem && (
  <DetailsView item={selectedCleaningItem} onClose={handleCloseDetails} />
)}
    </div>

)};
