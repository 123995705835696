import React, { useState } from 'react';

const DetailingForm = ({ item, onCancelEdit, onSuccessfulSubmit }) => {

  
    const [formData, setFormData] = useState({
      unitNumber: item.unitNumber,
      style: item.style,
      lastCleaned: item.lastCleaned,
      availStart: item.availStart,
      availEnd: item.availEnd,
      nextStart: item.nextStart,
      nextEnd: item.nextEnd
    });
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const apiEndpoint = "https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/updatedetailinglist"; // Update this with your API Gateway URL
  
      // Include 'id' and 'type' in the request body
      const requestBody = {
        id: item.id,
        type: item.type,
        ...formData,
      };
  
      try {
        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const responseJson = await response.json();

  
        // Reset form fields and close the form after successful submission
        setFormData({
          unitNumber: '',
          style: '',
          lastCleaned: '',
          availStart: '',
          availEnd: '',
          nextStart: '',
          nextEnd: ''
        });
        onSuccessfulSubmit(); // Call this after successful submission
        onCancelEdit();
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle error feedback to user here
      }
    };

  return (
    <div>
      <h2>Edit Detailing Item</h2>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="input-row">
          <div className="input-group">
            <label htmlFor="unitNumber">Unit Number:</label>
            <input
              type="text"
              id="unitNumber"
              name="unitNumber"
              value={formData.unitNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="style">Style:</label>
            <input
              type="text"
              id="style"
              name="style"
              value={formData.style}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="lastCleaned">Last Cleaned:</label>
            <input
              type="text"
              id="lastCleaned"
              name="lastCleaned"
              value={formData.lastCleaned}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="input-row">
          <div className="input-group">
            <label htmlFor="availStart">Availability Start:</label>
            <input
              type="text"
              id="availStart"
              name="availStart"
              value={formData.availStart}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="availEnd">Availability End:</label>
            <input
              type="text"
              id="availEnd"
              name="availEnd"
              value={formData.availEnd}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="nextStart">Next Start:</label>
            <input
              type="text"
              id="nextStart"
              name="nextStart"
              value={formData.nextStart}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="nextEnd">Next End:</label>
            <input
              type="text"
              id="nextEnd"
              name="nextEnd"
              value={formData.nextEnd}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="button-container">
          <button type="submit">Save</button>
          <button type="button" onClick={onCancelEdit}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default DetailingForm;