import React, { useState, useEffect } from 'react';
import TruckForm from './TruckForm.jsx'; // Import your form component

export default function TruckList() {
  const [trucks, setTrucks] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'unitNumber', direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');

  const apiUrl = 'https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/alltrucks?type=200';

  useEffect(() => {
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => setTrucks(data || []))
      .catch(error => console.error('Fetching error:', error));
  }, []);

  const sortedTrucks = React.useMemo(() => {
    let sortableItems = [...trucks];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems.filter(truck =>
      truck.unitNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      truck.manufacturer.toLowerCase().includes(searchTerm.toLowerCase()) ||
      truck.style.toLowerCase().includes(searchTerm.toLowerCase()) ||
      truck.assignment.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [trucks, sortConfig, searchTerm]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = (truck) => {
    setSelectedTruck(truck);
  };

  const handleCancelEdit = () => {
    setSelectedTruck(null);
  };

  // Inside TruckList component
const fetchTrucks = () => {
  fetch(apiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  })
    .then(response => response.json())
    .then(data => {
      setTrucks(data || []);
      // Ensure trucks are sorted initially as required
      requestSort('unitNumber');
    })
    .catch(error => console.error('Fetching error:', error));
};

useEffect(() => {
  fetchTrucks(); // Fetch trucks on component mount
}, []);

const handleAddTruck = () => {
  const newTruck = {
    id: new Date().toISOString(), // Current timestamp as ID
    type: 200, // Predefined type
    unitNumber: '', // Blank fields for the new truck form
    manufacturer: '',
    style: '',
    assignment: '',
  };
  setSelectedTruck(newTruck);
};

return (
  <div className="container listDiv truckContent">
    <div className="search-and-add-bar">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button onClick={handleAddTruck}>Add Truck</button> {/* Add Truck Button */}
    </div>
      <div className="table-container">
  <table>
    <thead>
      <tr>
        <th className="styleCol1" onClick={() => requestSort('unitNumber')}>Unit Number</th>
        <th className="styleCol1" onClick={() => requestSort('manufacturer')}>Manufacturer</th>
        <th className="styleCol2" onClick={() => requestSort('style')}>Style</th>
        <th className="styleCol1" onClick={() => requestSort('assignment')}>Assignment</th>
      </tr>
    </thead>
    {/* Apply the scrollable-tbody class here */}
    <tbody className="scrollable-tbody">
    {sortedTrucks.map((truck) => (
      <tr key={truck.id} onClick={() => handleRowClick(truck)}>
        <td className="styleCol1">{truck.unitNumber}</td>
        <td className="styleCol1">{truck.manufacturer}</td>
        <td className="styleCol2">{truck.style}</td>
        <td className="styleCol1">{truck.assignment}</td>
      </tr>
    ))}
    </tbody>
  </table>
</div>
      {selectedTruck && (
      <div className="container formDiv">
        <TruckForm truck={selectedTruck} onCancelEdit={handleCancelEdit} onSuccessfulSubmit={fetchTrucks} />
      </div>
    )}
  </div>
);
}
