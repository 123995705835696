import React, { useState, useEffect, useMemo } from 'react';
import WoDetails from './WoDetails.jsx';
import moment from 'moment';

export default function WorkOrders() {
    const [items, setItems] = useState([]);
    const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
    const [checkboxStates, setCheckboxStates] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: 'unitNumber', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState('');
    const [timeframe, setTimeframe] = useState('2 weeks');
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');
    const [editMode, setEditMode] = useState(false);

    const apiUrl = 'https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/alltrucks?type=300';

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            setItems(data || []);
            const initialCheckStates = {};
            data.forEach(item => {
                initialCheckStates[item.id] = false;
            });
            setCheckboxStates(initialCheckStates);
        } catch (error) {
            console.error('Fetching error:', error);
        }
    };

    const sortedAndFilteredItems = useMemo(() => {
        const filtered = items.filter(item => {
            const itemDate = moment(item.dateEntered, 'MM/DD/YYYY');
            const timeFrameValid = {
                '2 weeks': moment().diff(itemDate, 'weeks') <= 2,
                '3 months': moment().diff(itemDate, 'months') <= 3,
                '6 months': moment().diff(itemDate, 'months') <= 6,
                'all': true
            }[timeframe] || true;
    
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            return timeFrameValid && (
                item.unitNumber.toLowerCase().includes(lowerCaseSearchTerm) ||
                item.woNum.toLowerCase().includes(lowerCaseSearchTerm) ||
                item.description.toLowerCase().includes(lowerCaseSearchTerm) ||
                item.unitHealth.toLowerCase().includes(lowerCaseSearchTerm) ||
                item.Vendor.toLowerCase().includes(lowerCaseSearchTerm) ||
                item.status.toLowerCase().includes(lowerCaseSearchTerm) ||
                item.blockID.toLowerCase().includes(lowerCaseSearchTerm)
            );
        });
    
        return filtered.sort((a, b) => {
            if (!a[sortConfig.key] || !b[sortConfig.key]) return 0;
            return (
                a[sortConfig.key].toString().localeCompare(b[sortConfig.key].toString(), 'en', {numeric: true}) * 
                (sortConfig.direction === 'ascending' ? 1 : -1)
            );
        });
    }, [items, sortConfig, timeframe, searchTerm]);  // Added searchTerm to dependencies
    

    const handleCheckboxChange = (item) => {
        setCheckboxStates(prev => ({
            ...prev,
            [item.id]: !prev[item.id]
        }));
    };

    const handleTimeframeChange = (e) => {
        setTimeframe(e.target.value);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleViewDetails = (item, isEditMode) => {
      setSelectedWorkOrder(item);
      setEditMode(isEditMode);
      setIsDetailsVisible(true);
  };

    const handleCloseDetails = () => {
      setIsDetailsVisible(false);
      setSelectedWorkOrder(null);
      setSelectedAction('');
      setEditMode(false);
      fetchItems();
  };

  const handleActionChange = (event) => {
    const action = event.target.value;
    setSelectedAction(action);
    setEditMode(action === 'editDetails' || action === 'newItem'); // Set editMode based on action

    const selectedItem = items.find(item => checkboxStates[item.id]);
    switch (action) {
        case 'viewDetails':
        case 'editDetails':
            handleViewDetails(selectedItem, action === 'editDetails');
            break;
        case 'markCompleted':
            markSelectedAsCompleted();
            setSelectedAction('');
            break;
        case 'newItem':
            handleViewDetails({
                id: new Date().getTime().toString(),
                unitNumber: '',
                woNum: '',
                description: '',
                blockID: '',
                unitHealth: '',
                Vendor: '',
                dateEntered: moment().format('MM/DD/YYYY'),
                dateCompleted: '',
                status: '',
                type: 300
            }, true);
            break;
        default:
            console.log('No action selected');
    }
};


const markSelectedAsCompleted = () => {
    const updatedItems = items.filter(item => {
        return checkboxStates[item.id] && (!item.dateCompleted || item.dateCompleted === '');
    }).map(item => {
        return { ...item, dateCompleted: moment().format('MM/DD/YYYY') };
    });

    // Update each changed item in the backend individually
    updatedItems.forEach(updateItem);

    // Optionally refresh items or handle UI updates
    fetchItems();
};

const updateItem = async (item) => {
    await fetch('https://2af2e5kjj9.execute-api.us-east-1.amazonaws.com/prod/updateWO', {
        method: 'Post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(item)
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Failed to update item');
        }
        return response.json();
    })
    .then(data => {
        console.log('Update successful for item:', item.id, data);
    })
    .catch(error => {
        console.error('Error updating item:', item.id, error);
    });
};

const isMarkCompletedAvailable = Object.values(checkboxStates).some(v => v) &&
    items.every(item => checkboxStates[item.id] ? item.dateCompleted === '' : true);



    const isAnySelected = Object.values(checkboxStates).some(state => state);
    const isAllSelectedPending = items.every(item => 
      checkboxStates[item.id] ? item.dateCompleted === '' : true
    );
    const numberOfSelectedItems = Object.values(checkboxStates).filter(checked => checked).length;
    const showDetails = () => setIsDetailsVisible(true);
    const hideDetails = () => setIsDetailsVisible(false);

    return (
        <div className="container listDiv">
            <div className="search-and-add-bar">
                <div>
                    <label className="input-label">Timeframe</label>
                    <select value={timeframe} onChange={handleTimeframeChange} className="timeframe-dropdown">
                        <option value="2 weeks">2 Weeks</option>
                        <option value="3 months">3 Months</option>
                        <option value="6 months">6 Months</option>
                        <option value="all">All</option>
                    </select>
                </div>
                <div>
                    <label className="input-label">Search</label>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div>
                    <label className="input-label">Actions</label>
                    <select
                        className="action-dropdown"
                        value={selectedAction}
                        onChange={handleActionChange}
                    >
                        <option value="">Select Action</option>
                        <option value="editDetails" disabled={!isAnySelected}>Edit Item</option>
                        <option value="markCompleted" disabled={!isMarkCompletedAvailable}>Mark Completed</option>
                        <option value="newItem">New Item</option>
                        <option value="viewDetails" disabled={!isAnySelected}>View Details</option>
                    </select>
                </div>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th className="checkbox-header"></th>
                            <th className="styleCol1" onClick={() => requestSort('unitNumber')}>Unit</th>
                            <th className="styleCol1" onClick={() => requestSort('woNum')}>Work Order</th>
                            <th className="styleCol1" onClick={() => requestSort('description')}>Description</th>
                            <th className="styleCol1" onClick={() => requestSort('unitHealth')}>Health</th>
                            <th className="styleCol1" onClick={() => requestSort('status')}>Status</th>
                            <th className="styleCol1" onClick={() => requestSort('Vendor')}>Vendor</th>
                            <th className="styleCol1" onClick={() => requestSort('blockID')}>Block ID</th>
                            <th className="styleCol1" onClick={() => requestSort('dateEntered')}>Entered</th>
                            <th className="styleCol1" onClick={() => requestSort('dateCompleted')}>Completed</th>
                        </tr>
                    </thead>
                    <tbody className="scrollable-tbody">
                        {sortedAndFilteredItems.length > 0 ? sortedAndFilteredItems.map((item) => (
                            <tr key={item.id}>
                                <td className="checkbox-cell">
                                    <input
                                        type="checkbox"
                                        checked={checkboxStates[item.id] || false}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                </td>
                                <td className="styleCol1">{item.unitNumber}</td>
                                <td className="styleCol1">{item.woNum}</td>
                                <td className="styleCol1">{item.description}</td>
                                <td className="styleCol1">{item.unitHealth}</td>
                                <td className="styleCol1">{item.status}</td>
                                <td className="styleCol1">{item.Vendor}</td>
                                <td className="styleCol1">{item.blockID}</td>
                                <td className="styleCol1">{item.dateEntered}</td>
                                <td className="styleCol1">{item.dateCompleted}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td colSpan="10" style={{ textAlign: 'center' }}>No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {isDetailsVisible && selectedWorkOrder && (
                <WoDetails item={selectedWorkOrder} onClose={handleCloseDetails} editMode={editMode}  />
            )}
        </div>
    );
}